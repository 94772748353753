import Container from "react-bootstrap/Container";
import Map from '../assets/Campus-Map-2024.png'
import Button from 'react-bootstrap/Button'
import RegistrationSection from '../components/registrationSection'
export default function Location() {

  const link = () => {
    window.open("https://www.brunel.ac.uk/about/documents/pdf/Campus-Map-2021.pdf");
  }

  return (
    <div>
    <Container>
      <main style={{ padding: 30, paddingTop: 16}}>
      <div style={{backgroundColor: '#ffffaa', padding: 8, paddingLeft: 24}}>
          <p style={{fontSize: 14, fontWeight: '600', marginBottom: 0, paddingBottom: 0}}>Joining the workshop online?</p>
          <p style={{fontSize: 12, marginTop: 0, marginBottom: 0}}>a Zoom link will be emailed to you in advance of the workshop.</p>
        </div>
        
        <h1 style={{ marginTop: 20, marginBottom: 10 }}>Venue</h1>
        <div style={{padding: 20}}>
        <p>MCST 054, Michael Sterling Building, Brunel University London<br /><br />
        Brunel University London<br />
        Kingston Lane<br />
        Uxbridge<br />
        Middlesex<br />
        UB8 3PH</p>
        </div>
        <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
          <p>All seminars will take place in the <a href="https://www.brunel.ac.uk/virtualtour/?filter=academic&content=1009&tour=1016&frompin=1002" target="_blank" rel="noreferrer">Michael Sterling</a> Building</p>
          <img style={{ borderRadius: 10, border: '1px solid #cccccc', maxWidth: '800px', width: '100%'}} src={Map} />
          {/* <p style={{marginLeft: 10, marginTop: 10}}><b>Please enter through the "Hospitality at Brunel" entrance. An arrow pointing to the location of this entrance can be found on the map above.</b></p> */}
          <div style={{paddingTop: 10}}>
            <Button size="sm" onClick={() => link()}>Download campus map</Button>
          </div>
        </div>

        

        <h2 style={{ marginTop: 20, marginBottom: 10 }}>How to get here</h2>

        <div style={{ padding: 20 }}>
          <h4>By bus from:</h4>
          <p>Uxbridge station: U3 (bus stop: Brunel University London)</p>
          <p>West Drayton station: U3 (bus stop: Brunel University London), U5 (bus stop: Huxley close and 5' walk to Brunel University London)</p>
          {/* <p>Uxbridge station: U1, U4, U7, U2 (takes longer)</p> */}
          {/* <p>West Drayton station: U1, U3, U5 (please change at Hillingdon hospital and take U2, U4, U7)</p> */}
        </div>

        <div style={{ padding: 20 }}>
          <h4>By car:</h4>
          <p>Brunel University London<br />
            Kingston Lane<br />
            Uxbridge<br />
            Middlesex UB8 3PH</p>

          <h4>Parking</h4>
          <p>Parking restrictions are operational between 8am to 4pm, Monday to Friday. </p>
          <p>We advise that you arrive at least 30 minutes prior to the start of an event to allow for parking.
            We also have ample Pay &amp; Display spaces on campus at a cost of £1 per hour, for those persons who have not been pre-booked by their Brunel Contact.</p>
          <p>For more information on parking please visit the section On Campus Parking at <a target="_blank" href="https://www.brunel.ac.uk/about/finding-us" rel="noreferrer">https://www.brunel.ac.uk/about/finding-us</a></p>
        </div>

        <p>Additional transport options such as Rail, Bike and walking can be found here <a target="_blank" href="https://www.brunel.ac.uk/about/finding-us" rel="noreferrer">https://www.brunel.ac.uk/about/finding-us</a></p>
      </main>
    </Container>
    {/* <RegistrationSection /> */}
    </div>
  );
}