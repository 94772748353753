import logo from './logo.svg';
import { useState } from 'react'

import { Outlet, NavLink, Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import { LinkContainer } from 'react-router-bootstrap'

import Footer from './components/footer'

// import BrunelLogo from './assets/brunel_logo.png'
import BrunelLogo from './assets/brunel-logo-white.png'

// import './App.css';

function App() {

  let activeStyle = {
    textDecoration: "none",
    color: '#91d4f6',
    margin: 8,
    fontSize: 15
  };
  let notActiveStyle = {
    textDecoration: "none",
    color: '#cccccc',
    margin: 8,
    fontSize: 15
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => setShowForm(false);
  const handleShowForm = () => setShowForm(true);

  const [showAcknowledge, setShowAcknowledge] = useState(false);

  const handleCloseAcknowledge = () => setShowAcknowledge(false);
  const handleShowAcknowledge = () => setShowAcknowledge(true);

  const [showRegistration, setShowRegistration] = useState(false);

  const handleRegistrationClose = () => setShowRegistration(false);
  const handleRegistrationShow = () => setShowRegistration(true);

  return (
    <div>
      <div style={{position: 'sticky', top: '0px', backgroundColor: '#0a0a0a', zIndex: 100}}>
      <Container>
        <Row>
          <Col xs="4" sm="4" md="2" lg="2">
            <div className="header__title">BBS 2024</div>
          </Col>

          <Col xs={{ span: 4, offset: 0, order: "last" }} sm={{ span: 4, offset: 0, order: "last" }} md="8" lg="8">
            <Navbar collapseOnSelect bg="#0a0a0a" style={{ justifyContent: 'flex-end' }} variant="dark" expand="md">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                <Nav style={{ textAlign: 'right' }}>
                  <LinkContainer to="/">
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/schedule">
                    <Nav.Link>Schedule</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/speakers">
                    <Nav.Link>Speakers</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/committee">
                    <Nav.Link>Committee</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/location">
                    <Nav.Link>Location</Nav.Link>
                  </LinkContainer>
                  {/* <LinkContainer to="/socialEvents">
                    <Nav.Link>Social Events</Nav.Link>
                  </LinkContainer> */}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>

          <Col xs="4" sm={{ span: 4, offset: 0 }} md={{ span: 2, order: "last" }} lg="2">
            <div className="header__register__button">
              <Button size="sm" variant="warning" onClick={handleShowForm}>Register</Button>
            </div>
          </Col>

        </Row>
      </Container>
      </div>

      <div style={{ backgroundColor: "#ffffff", paddingBottom: 0 }}>
        <Outlet />
      </div>

      <Footer />

      <Offcanvas show={showRegistration} onHide={handleRegistrationClose} placement="top">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>How to register</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>You can use the following form to register for any one or all of the sessions.</p>
          <Button size="sm">Register</Button>

          <hr />
          <p>This conference is open to students, progressing and experienced researchers.</p>
          <p>There is no registration fee!!!</p>
          <p>Lunch will only be provided for the Sequences session.</p>
        </Offcanvas.Body>
      </Offcanvas>

      {/* https://forms.office.com/r/gwiw1sPpsS */}

      <Modal show={showForm} fullscreen={true} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, margin: 0 }}>
          {/* <iframe width="100%" height="100%" src="https://forms.office.com/pages/responsepage.aspx?id=sZetTDVZA0GoZletmKFRfqrlRzNA3FhIqXT7a74cKNpUQlFPTlhQTksyTUY3MlhUMElaSEI4T1haNy4u&embed=true" /> */}
          {/* <iframe width="100%" height="100%" src="https://forms.office.com/pages/responsepage.aspx?id=sZetTDVZA0GoZletmKFRfnq72zYbLgtPnH0J-B1OJbdUNlpUS0Q0MFJXMkVWWjlGUFJFWEhHVVcyUy4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: 'none', maxWidth: '100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen />
           */}
           <iframe width="100%" height="100%" src="https://forms.office.com/pages/responsepage.aspx?id=sZetTDVZA0GoZletmKFRfqrlRzNA3FhIqXT7a74cKNpUMVJFRU1VWk9FMEpPSlhBWFQ2WTVaUEw2SC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: 'none', maxWidth: '100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen />
          
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default App;

// https://forms.office.com/Pages/ResponsePage.aspx?id=sZetTDVZA0GoZletmKFRfqrlRzNA3FhIqXT7a74cKNpUQlFPTlhQTksyTUY3MlhUMElaSEI4T1haNy4u&embed=true
// https://forms.office.com/e/XirpwN1Pfg

// https://forms.office.com/pages/responsepage.aspx?id=sZetTDVZA0GoZletmKFRfnq72zYbLgtPnH0J-B1OJbdUNlpUS0Q0MFJXMkVWWjlGUFJFWEhHVVcyUy4u&embed=true


