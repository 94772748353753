import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button'
import RegistrationSection from '../components/registrationSection'
export default function Committee() {
  const Member = ({ name, url }) => {
    return (
      <div className="committee__member">
      <p className="committee__member__name">{name}</p>
      <a className="committee__member__url" href={url} target="_blank" rel="noreferrer">Visit profile</a>
      </div>
    )
  }

  return (
    <div>
    <Container>
      <main style={{ padding:30}}>
        <h1 style={{marginTop: 20, marginBottom: 10}}>Steering committee</h1>
        <div style={{ padding: 20 }}>
          <Member name="Dr Sarath Chandra Dantu" url={"https://sites.google.com/view/sarathchandradantu/home"} />
          <Member name="Professor David Gilbert" url={"http://people.brunel.ac.uk/~csstdrg/"} />
          <Member name="Dr Alessandro Pandini" url={"https://www.brunel.ac.uk/people/alessandro-pandini"} />
        </div>

        <h1 style={{marginTop: 20, marginBottom: 10}}>Organising committee</h1>
        <div style={{ padding: 20 }}>
          <Member name="Dr Lorraine Ayad" url={"http://people.brunel.ac.uk/~blla008/"}/>
          <Member name="Dr Leila Ghanbar" url={"https://www.brunel.ac.uk/people/leila-ghanbar1"} />
          <Member name="Dr. Valeriia Haberland" url={"https://www.brunel.ac.uk/people/valeriia-haberland"} />
          <Member name="Dr Ferdoos Hossein Nezhad" url={"https://www.brunel.ac.uk/people/ferdoos-hosseinnezhad"} />
          <Member name="Dr Yasoda Jayaweera" url={"https://www.brunel.ac.uk/people/yasoda-jayaweera1"} />
          <Member name="Dr. Michelle Sahai" url={"https://www.brunel.ac.uk/people/michelle-sahai"} />
          <Member name="Pegerto Fernandez" url={"https://www.brunel.ac.uk/people/pegerto-fernandez"} />
          <Member name="Matthew O'Neill" url={"https://www.mattoneill.co.uk"} />
          <Member name="Namir Oues" url={"https://www.brunel.ac.uk/people/namir-oues"} />
        </div>
      </main>


      



    </Container>
    {/* <RegistrationSection /> */}
    </div>
  );
}