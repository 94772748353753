import { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'

import BrunelLogo from '../assets/brunel-logo-white.png'

export default function Footer() {

   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [showAcknowledge, setShowAcknowledge] = useState(false);

   const handleCloseAcknowledge = () => setShowAcknowledge(false);
   const handleShowAcknowledge = () => setShowAcknowledge(true);

   return (
      <>
         <div style={{ backgroundColor: "#0a0a0a" }}>
            <Container>
               <Row xs={1} sm={1} md={1} lg={3}>
                  <Col xs={{ span: 10, offset: 1, order: "last" }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 0, order: "first" }}>
                     <div className="footer__institution">
                        <img className="footer__institution__img" src={BrunelLogo} />
                        <p className="footer__institution__group">Brunel Computational Biology &copy; 2024</p>
                     </div>
                  </Col>

                  <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 0 }}>
                     <p className="footer__acknowledgements" onClick={handleShowAcknowledge}>Acknowledgements</p>
                  </Col>

                  <Col xs={{ span: 10, offset: 1, order: "first" }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 0, order: "last" }}>
                     <div className="footer__contactus">
                        <Button size="sm" variant="warning" onClick={handleShow}>Contact Us</Button>
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>

         <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
               <Offcanvas.Title>Contact Us</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <p><b>For any questions regarding the workshop, please reach us by email</b></p>
               {/* <p>Dr. Lorraine Ayad<br /><a href="mailto:lorraine.ayad@brunel.ac.uk">lorraine.ayad@brunel.ac.uk</a> or</p> */}
               <p>Dr. Sarath Dantu<br /><a href="mailto:sarath.dantu@brunel.ac.uk">sarath.dantu@brunel.ac.uk</a></p>
            </Offcanvas.Body>
         </Offcanvas>

         <Offcanvas varient="dark" show={showAcknowledge} onHide={handleCloseAcknowledge} placement="bottom" style={{ height: 'auto', backgroundColor: '#0a0a0a', color: "#aaaaaa", padding: 20 }}>
            <Offcanvas.Header closeButton closeVariant="white">
               <Offcanvas.Title>Acknowledgements</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <p>Background photo by <a href="https://unsplash.com/@dancristianpaduret?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Dan Cristian Pădureț</a> on <a href="https://unsplash.com/photos/h3kuhYUCE9A?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
               <p>BBW 2024 website created Matthew O'Neill</p>
            </Offcanvas.Body>
         </Offcanvas>
      </>
   )
}
  