import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import SpeakerHolder from '../assets/speakerHolder.jpg'
import FaruckMorcos from '../assets/FaruckMorcos.jpg'
import AlexBateman from '../assets/AlexBateman.jpg'
import RayanChikhi from '../assets/RayanChikhi.jpg'
import AikChoonTan from '../assets/AikChoonTan.jpg'
import SandraAlvarezCarretero from '../assets/SandraAlvarezCarretero.jpg'
import ZaminIqbal from '../assets/ZaminIqbal.jpg'

import RegistrationSection from '../components/registrationSection'

import GitHub from '../assets/GitHub-Mark-Light-64px.png'
import LinkedIn from '../assets/LI-In-Bug.png'
import { speakers } from '../data/speakers'
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import '../styles/speakers.css';


export default function Speakers() {

    const goToUrl = (url) => {
        window.open(url);
    }

    // var InlineMath = ReactKaTeX.InlineMath;

    return (
        <div>
            <main style={{ paddingTop: 30, backgroundColor: '#fafafa' }}>
                <Container style={{ textAlign: 'left' }}>
                    <h1 style={{ marginTop: 20, marginBottom: 20 }}>Speakers</h1>
                    <div style={{ borderBottom: '1px solid #121d2a' }}><p>We are delighted to have the following guest speakers sharing their latest research at our symposium.</p></div>
                    {/* <br /><br /> */}
                    {/* <div>
                    If <InlineMath>{"\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g_{p}}{p} \\small = 0"}</InlineMath> there is an optimal algorithm running in&nbsp;
                    <InlineMath>{"{O}  \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m}{m} \\scriptsize\\right )"}</InlineMath>-time on average.
                    </div>

                    <div>
                    If <InlineMath>{"\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g_{p}}{p} \\small = 1 - {\\normalsize\\varepsilon}"}</InlineMath> there is an optimal algorithm running in&nbsp;
                    <InlineMath>{"{O} \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m\\thickspace log_{2}\\thickspace{p}}{m} \\right ) "}</InlineMath>-time on average.
                    </div>

                    <div>
                    If <InlineMath>{"\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g}{m} = \\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\small 1 - f\\left (m \\right )\\small = 1"}</InlineMath> any algorithm takes at least&nbsp;
                    <InlineMath>{"{\\Omega} \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m}{f\\left (m\\right )} \\right ) "}</InlineMath>-time on average.
                    </div> */}

                </Container>
                {/* Ayad, Lorraine */}

                <Container>
                    <div className="Speakers">
                    {
                        speakers.map((speaker) => {
                            return (
                                <div className="Speaker">
                                    <div className="SpeakerInfo">
                                        <div>
                                            <div className="SpeakerName">{speaker?.name || ""}</div>
                                            <div className="SpeakerInsitution">{speaker?.institution || ""}</div>
                                        </div>
                                        {speaker?.profileUrl && <div className="SpeakerProfile"><a href={speaker.profileUrl} target="_blank">Visit profile</a></div>}
                                    </div>
                                    <div className="SpeakerPresentation">
                                        {speaker?.presentation?.title && <div className="SpeakerPresentationTitle">{speaker.presentation.title}</div>}
                                        
                                        {speaker?.presentation?.abstract && Object.keys(speaker?.presentation?.abstract).length > 0 && speaker.presentation.abstract.map((paragraphs, key) => {
                                            const { textPara = null, multiPara = null} = paragraphs;
                                            
                                            //if a text only paragraph 
                                            if(textPara !== null) {
                                                return <div className="SpeakerPresentationAbstract" key={`${speaker.last}_${key}`}>{textPara}</div>;
                                            }

                                            //if a multi part paragraph containing text and equations
                                            let para = '';

                                            if(multiPara !== null) {
                                                para = multiPara.map((part, key) => {
                                                    const { type, content } = part;
                                                    if(type === 'text') {
                                                        return (<span className="SpeakerAbstractLineMultiPart">{content}</span>); //content;
                                                    }

                                                    if(type === 'eq') {
                                                        return (<span className="SpeakerAbstractLineMultiPart"><InlineMath>{content}</InlineMath></span>); //content;
                                                    }
                                                });
                                            }
                                            
                                            return (
                                                <div className="SpeakerPresentationAbstract" key={`${speaker.last}_${key}`}>{para}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                </Container>
            </main>
        </div>
    );
}