import Container from "react-bootstrap/Container";
import Map from '../assets/Campus-Map-2021.png'
import Button from 'react-bootstrap/Button'
import RegistrationSection from '../components/registrationSection'

import AlBasha from '../assets/AlBasha Logo_edited.png.webp'
import CasaDoFrango from '../assets/casa-do-frango.png'

import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'

import '../styles/socialEvents.css'

const MapSection = ({ location, zoomLevel }) => {
  // const location = {
  //   address: '1600 Amphitheatre Parkway, Mountain View, california.',
  //   lat: 37.42216,
  //   lng: -122.08427,
  // }
  return (
  <div className="map">
    <h2 className="map-h2">Come Visit Us At Our Campus</h2>

    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDK49RhIIjCb6_i-_Q3Aw0Fql0oaf5e1Oo' }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
  )
}

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)


export default function SocialEvents() {

  const link = () => {
    window.open("https://www.brunel.ac.uk/about/documents/pdf/Campus-Map-2021.pdf");
  }

  const location = {
    address: 'Casa do Frango, Piccadilly 31-33 Heddon St, London W1B 4BL',
    lat: 51.5110731,
    lng: -0.1376536,
  }

  return (
    <div>
      <Container>
        <main style={{ padding: 30, paddingTop: 16 }}>
          <h1 style={{ fontSize: 32, marginTop: 20, marginBottom: 24 }}>Social Events</h1>

          {/* <div style={{marginBottom: 32, backgroundColor: '#eeeeee', padding: 24}}>
            <h3 style={{fontSize: 24, fontWeight: '600'}}>Monday 12th June</h3>
            <img src={CasaDoFrango} style={{width: 100, marginBottom: 12, marginTop: 12}} />
            <p><b>Casa do Frango Piccadilly</b><br />
            31-33 Heddon St, London W1B 4BL<br />
            <a style={{paddingBottom: 8, fontSize: 12}} href="https://casadofrango.co.uk/" target="_blank">https://casadofrango.co.uk/</a></p>
            <p style={{paddingBottom: 0, marginBottom: 0}}>
              <b>Meet at 6:30 pm</b><br/>
              <span style={{fontSize: 12}}>Map: <a href="https://goo.gl/maps/4d4jRXVYau5HMBHF7?coh=178573&entry=tt" target="_blank">https://goo.gl/maps/4d4jRXVYau5HMBHF7</a></span>
            </p>
          </div> */}

          {/* <div style={{marginBottom: 32, backgroundColor: '#eeeeee', padding: 24}}>
            <h3 style={{fontSize: 24, fontWeight: '600'}}>Tuesday 13th June</h3>
            <img src={AlBasha} style={{width: 100, marginBottom: 12, marginTop: 12}} />
            <p><b>Al Basha Restaurant</b><br />
            48 Knightsbridge, London SW1X 7JN<br />
            <a style={{paddingBottom: 8, fontSize: 12}} href="https://www.albasha.uk/" target="_blank">https://www.albasha.uk/</a></p>
            <p style={{paddingBottom: 0, marginBottom: 0}}>
              <b>Meet at 6:30 pm</b><br />
              <span style={{fontSize: 12}}>Map: <a href="https://goo.gl/maps/bnTkAKap2jXpJDvK9?coh=178573&entry=tt" target="_blank">https://goo.gl/maps/bnTkAKap2jXpJDvK9</a></span>
            </p>
          </div> */}

          </main>
      </Container>
      {/* <RegistrationSection /> */}
    </div>
  );
}