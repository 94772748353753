const Friday14June2024 =
{
  date: "Friday 7th June 2024",
  duration: "11:00-16:00 BST",
  title: "Sequence alignment & Graphs",
  subinfo: "Alessandro Pandini",
  sessions: [
    {
      title: "Session 1: Biomolecular Design",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:10-11:30",
          title: "Saleh Alyemni, UCL",
          presentation: "Allosteric Signalling in SOAT enzymes"
        },
        {
          format: "talk",
          time: "11:30-11:50",
          title: "Işılay Öztürk, Cagliari University",
          presentation: "Systematic investigation of chelator-radionuclides compounds by computational methods"
        },
        {
          format: "keynote",
          time: "11:50-12:25",
          title: "Dr. Femi Olorunniji, Liverpool John Moores University",
          presentation: "Keynote: Regulation of site-specific recombination by large serine integrases"
        },
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "12:30-13:30"
    },
    {
      title: "Session 2: Biomolecular Evolution",
      format: "session",
      chair: "Michelle Sahai",
      events: [
        {
          format: "talk",
          time: "13:30-13:50",
          title: "Manming Xu, UCL",
          presentation: "Coevolution Insights into beta lactamases: A Dynamical Analysis of TEM-1 and SHV-1"
        },
        {
          format: "talk",
          time: "13:50-14:10",
          title: "Jing Gu, UCL: ",
          presentation: "Using Markov state model and Deep Learning to explore the dynamic difference of Class A β-Lactamases"
        },
        {
          format: "keynote",
          time: "14:10-14:45",
          title: "Prof. Richard Goldstein, UCL",
          presentation: "Keynote"
        },
      ]
    },
    {
      title: "Break",
      format: "break",
      time: "14:45-15:00"
    },
    {
      title: "Session 3: Biomolecular Networks",
      format: "session",
      chair: "Valeriia Haberland",
      events: [
        {
          format: "talk",
          time: "15:00-15:20",
          title: "Samuel Buchanan, QUB",
          presentation: "Utilising systems biology modelling to identify biological drivers and druggable targets in Multiple Myeloma"
        },
        {
          format: "talk",
          time: "15:20-15:40",
          title: "Rachael Adamson, QUB",
          presentation: "Analysis of Proteomic Information-Flow Networks in Hormone Driven Breast Cancer Patients: A Systems Medicine Approach to New Clinical Tools"
        },
        {
          format: "talk",
          time: "15:40-16:00",
          title: "Sivasharmini Ganes hamoorthy, Coventry University",
          presentation: "A Comparative Study on Inferring Gene Regulatory Network with Graph Neural Networks"
        },
        {
          format: "keynote",
          time: "16:00-16:35",
          title: "Henning Hermjakob, EBI",
          presentation: "Keynote: EMBL-EBI Network resources: From Interactions to Models"
        },
      ]
    },
    {
      title: "Poster session",
      format: "poster",
      time: "16:35-18:00",
      message: "Refreshments and snacks",
      events: [
        {
          format: "poster",
          time: "16:35-18:00",
          message: "Refreshments and snacks"
        }
      ]
      
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Casa do Frango, Piccadilly",
    //   message: "More information"
    // }
  ]
}

const Friday14June2024Session2 =
{
  date: "Friday 7th June 2024",
  duration: "11:00-16:00 BST",
  title: "Sequence alignment & Graphs",
  subinfo: "Chaired by TBD.",
  sessions: [
    {
      title: "Session 1: Biomolecular Design",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:10-11:30",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:30-11:50",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:50-12:10",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "12:10-12:45",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "12:45-13:45"
    },
    {
      title: "Session 2: Biomolecular Evolution",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "13:45-14:05",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:05-14:25",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:25-14:45",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:45-15:20",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Break",
      format: "break",
      time: "13:20-13:40"
    },
    {
      title: "Session 3: Biomolecular Networks",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "15:40-16:00",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:20-16:40",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:40-17:00",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "17:00-17:35",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Post session",
      format: "social",
      time: "17:35-19:00",
      message: "Refreshments and snacks"
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Casa do Frango, Piccadilly",
    //   message: "More information"
    // }
  ]
}

const Friday14June2024Session3 =
{
  date: "Friday 7th June 2024",
  duration: "11:00-16:00 BST",
  title: "Sequence alignment & Graphs",
  subinfo: "Chaired by TBD.",
  sessions: [
    {
      title: "Session 1: Biomolecular Design",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:10-11:30",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:30-11:50",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:50-12:10",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "12:10-12:45",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "12:45-13:45"
    },
    {
      title: "Session 2: Biomolecular Evolution",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "13:45-14:05",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:05-14:25",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:25-14:45",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:45-15:20",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Break",
      format: "break",
      time: "13:20-13:40"
    },
    {
      title: "Session 3: Biomolecular Networks",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "15:40-16:00",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:20-16:40",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:40-17:00",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "17:00-17:35",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Post session",
      format: "social",
      time: "17:35-19:00",
      message: "Refreshments and snacks"
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Casa do Frango, Piccadilly",
    //   message: "More information"
    // }
  ]
}

const Friday14June2024Session4 =
{
  date: "Friday 7th June 2024",
  duration: "11:00-16:00 BST",
  title: "Sequence alignment & Graphs",
  subinfo: "Chaired by TBD.",
  sessions: [
    {
      title: "Session 1: Biomolecular Design",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:10-11:30",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:30-11:50",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "11:50-12:10",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "12:10-12:45",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "12:45-13:45"
    },
    {
      title: "Session 2: Biomolecular Evolution",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "13:45-14:05",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:05-14:25",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:25-14:45",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "14:45-15:20",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Break",
      format: "break",
      time: "13:20-13:40"
    },
    {
      title: "Session 3: Biomolecular Networks",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "15:40-16:00",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:20-16:40",
          title: "PhD: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "16:40-17:00",
          title: "PostDoc: ",
          presentation: "TBD"
        },
        {
          format: "talk",
          time: "17:00-17:35",
          title: "Keynote: ",
          presentation: "TBD"
        },
      ]
    },
    {
      title: "Post session",
      format: "social",
      time: "17:35-19:00",
      message: "Refreshments and snacks"
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Casa do Frango, Piccadilly",
    //   message: "More information"
    // }
  ]
}

const Monday12June =
{
  date: "Monday 12th June 2024",
  duration: "11:00-16:00 BST",
  title: "Sequence alignment & Graphs",
  subinfo: "Chaired by TBD.",
  sessions: [
    {
      title: "Session 1: Sequence alignment",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:00-11:45",
          title: "Talk 1: Dr Carl Barton",
          presentation: "Average-case complexity of pattern matching with wildcards"
        },
        {
          time: "11:45-12:15",
          title: "Break"
        },
        {
          format: "talk",
          time: "12:15-13:00",
          title: "Talk 2: Dr Lorraine Ayad",
          presentation: "Seedability: Optimising alignment parameters for sensitive sequence comparison"
        }
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "13:00-14:00"
    },
    {
      title: "Session 2: Graphs",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "14:00-14:45",
          title: "Talk 3: Prof Reidun Twarock",
          presentation: "Hamiltonian Paths Analysis: Viral geometry informed data mining of viral genomes"
        },
        {
          time: "14:45-15:15",
          title: "Break"
        },
        {
          format: "talk",
          time: "15:15-16:00",
          title: "Talk 4: Dr Mostafa Herajy",
          presentation: "A graphical approach for hybrid simulation of 3D diffusion bio-models via coloured hybrid Petri nets"
        }
      ]
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Casa do Frango, Piccadilly",
    //   message: "More information"
    // }
  ]
}

const Tuesday13June =
{
  date: "Tuesday 13th June 2024",
  duration: "11:00-16:00 BST",
  title: "Trees & Numerical Models",
  subinfo: "Chaired by TBD.",
  sessions: [
    {
      title: "Session 1: Trees",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "11:00-11:45",
          title: "Talk 1: Dr Giulia Bernardini",
          presentation: "Reconstructing Phylogenetic Networks via Cherry Picking and Machine Learning"
        },
        {
          time: "11:45-12:15",
          title: "Break"
        },
        {
          format: "talk",
          time: "12:15-13:00",
          title: "Talk 2: Prof M. Sohel Rahman",
          presentation: "Phylogeny-aware multi-objective optimization approaches"
        }
      ]
    },
    {
      title: "Lunch",
      format: "lunch",
      time: "13:00-14:00"
    },
    {
      title: "Session 2: Numerical Models",
      format: "session",
      chair: "Session chair to be announced",
      events: [
        {
          format: "talk",
          time: "14:00-14:45",
          title: "Talk 3: Dr Veronica Guerrini",
          presentation: "Lossy compression preserving variant calling based on the BWT of FASTQ data"
        },
        {
          time: "14:45-15:15",
          title: "Break"
        },
        {
          format: "talk",
          time: "15:15-16:00",
          title: "Talk 4: Mr Mark Blyth",
          presentation: "Experimental bifurcation analysis of biological systems using control-based continuation"
        }
      ]
    },
    // {
    //   format: "social",
    //   time: "18:30",
    //   title: "Dinner at Al Basha, Knightsbridge",
    //   message: "More information"
    // }
  ]
}

export { Friday14June2024, Monday12June, Tuesday13June };