import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import "../bbw.css"

import ArrowUp from '../assets/arrow_up.svg'
import ArrowDown from '../assets/arrow_down.svg'

import { Friday14June2024, Monday12June, Tuesday13June } from '../data/schedule';

import bgImage from '../assets/backgroundImage.jpg'

import RegistrationSection from '../components/registrationSection'
import { LinkContainer } from 'react-router-bootstrap';


import '../styles/schedule.css'

export default function Schedule() {

    const [dAK, setDAK] = useState(0);

    const { activeVal } = useParams();

    useEffect(() => {
        if (parseInt(activeVal) > 0) {
            setDAK(parseInt(activeVal))
        }
    }, [])





    // var series = {
    //     welcome: {
    //         title: "Welcome Lunch",
    //         date: "Monday 12th June 2024, 11:30-13:00 BST",
    //         subinfo: "TBD",
    //         type: 'message',
    //         message: 'Lunch will be served xyz'
    //     },
    //     sequences: {
    //         title: "12th June: Sequence alignment & Graphs",
    //         date: "Monday 12th June 2024, 11:00-16:00 BST",
    //         // subinfo: "Chaired by TBD.",
    //         subinfo: "",
    //         type: 'table',
    //         table: [
    //             { type: "speaker", event: "Session 1: Sequence alignment", time: "" },
    //             { type: "speaker", event: "Talk 1: Dr Carl Barton", time: "11:00-11:45" },
    //             { type: "break", event: "Break", time: "11:45-12:15" },
    //             { type: "speaker", event: "Talk 2: Dr Lorraine Ayad", time: "12:15-13:00" },
    //             { type: "lunch", event: "Lunch", time: "13:00-14:00" },
    //             { type: "speaker", event: "Session 2: Graphs", time: "" },
    //             { type: "speaker", event: "Talk 3: Prof Reidun Twarock", time: "14:00-14:45" },
    //             { type: "break", event: "Break", time: "14:45-15:15" },
    //             { type: "speaker", event: "Talk 4: Dr Mostafa Herajy", time: "15:15-16:00" }
    //         ]
    //     },
    //     graphs: {
    //         title: "13th June: Trees & Numerical Models",
    //         date: "Tuesday 13th June 2024, 11:00-16:00 BST",
    //         // subinfo: "Chaired by TBD.",
    //         subInfo: "",
    //         type: 'table',
    //         table: [
    //             { type: "speaker", event: "Session 1: Trees", time: "" },
    //             { type: "speaker", event: "Talk 1: Dr Giulia Bernardini", time: "11:00-11:45" },
    //             { type: "break", event: "Break", time: "11:45-12:15" },
    //             { type: "speaker", event: "Talk 2: Prof Sohel Rahman", time: "12:15-13:00" },
    //             { type: "lunch", event: "Lunch", time: "13:00-14:00" },
    //             { type: "speaker", event: "Session 2: Numerical Models", time: "" },
    //             { type: "speaker", event: "Talk 3: Dr Veronica Guerrini", time: "14:00-14:45" },
    //             { type: "break", event: "Break", time: "14:45-15:15" },
    //             { type: "Speaker", event: "Talk 4: Dr Lucia Marucci", time: "15:15-16:00" }
    //         ]
    //     },
    //     statisticalModels: {
    //         title: "Statistical Modelling",
    //         date: "Friday 20th May 2022, 13:00-16:00 BST",
    //         subinfo: "TBD",
    //         type: 'table',
    //         table: [
    //             { type: "lunch", event: "Lunch", time: "11:30-13:00" },
    //             { type: "speaker", event: "Dr Sandra Alvarez-Carretero", time: "13:00-14:00" },
    //             { type: "break", event: "Coffee break", time: "14:00-14:30" },
    //             { type: "speaker", event: "Dr Aik Choon Tan", time: "14:30-15:30" },
    //             { type: "conclusions", event: "Discussion and closing remarks", time: "15:30-16:00" },
    //         ]
    //     },
    //     roundTableDiscussions: {
    //         title: "Round Table Discussions",
    //         date: "Friday 27th May 2022, 13:00-15:30 BST",
    //         subinfo: "TBD",
    //         type: 'table',
    //         table: [
    //             { type: "lunch", event: "Lunch", time: "11:30-13:00" },
    //             { type: "speaker", event: "Round table discussions", time: "13:00-15:00" },
    //             { type: "break", event: "Coffee break", time: "15:00-15:30" },
    //         ]
    //     }
    // }


    // const SeriesItem = ({ item, id }) => {
    //     return (
    //         <Card style={{ marginBottom: 10, padding: 0, borderRadius: 5 }}>
    //             <Card.Header style={{ padding: 0, borderBottomWidth: 0 }}>
    //                 <ContextAwareToggle eventKey={id} item={item} />
    //             </Card.Header>
    //             <Accordion.Collapse eventKey={id} style={{ borderTopWidth: 10 }}>
    //                 {item.type == "message" ? (
    //                     <Card.Body style={{ backgroundColor: '#ffffff', border: 0 }}>
    //                         <div>{item.message}</div>
    //                     </Card.Body>
    //                 ) : (
    //                     <Card.Body style={{ backgroundColor: '#f0f8ff', border: 0 }}>
    //                         <Table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Time</th>
    //                                     <th>Schedule</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {
    //                                     item.table.map(item => (
    //                                         <tr>
    //                                             <td>{item.time}</td>
    //                                             <td>{item.event}</td>
    //                                         </tr>
    //                                     ))
    //                                 }
    //                             </tbody>
    //                         </Table>
    //                     </Card.Body>
    //                 )}
    //             </Accordion.Collapse>
    //         </Card>
    //     )
    // }

    const SeriesItem2 = ({ item, id }) => {

        const { date, duration, title, subinfo, sessions } = item;
        const headerInfo = { date, duration, title, subinfo, sessions };


        return (
            <div className="ScheduleAccordian">
                <ContextAwareToggle2 info={headerInfo} eventKey={id} />

                <Accordion.Collapse eventKey={id}>
                    <div className="ScheduleAccordianData">
                        {sessions.map((item, index) => {

                            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;

                            if (format === 'session') {
                                return (<div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                    <div className="HomeModalSessionTitle">{title}</div>
                                    <div className="HomeModalSessionChair">{chair}</div>
                                    {item.events.map((event, index) => {
                                        return (
                                            <div className="HomeModalSession">
                                                <div className="HomeModalSessionTime">{event.time}</div>
                                                <div className="HomeModalSessionEvent">{event.title}</div>
                                                <div className="HomeModalSessionPresentation">{event.presentation}</div>
                                            </div>)
                                    })}
                                </div>)
                            }

                            if (format === 'lunch') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                    </div>
                                )
                            }

                            if (format === 'social') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                        <LinkContainer to="/socialEvents">
                                            <div className="HomeModalSessionLink">{message}</div>
                                        </LinkContainer>
                                        {/* <div className="HomeModalSessionEvent"><a href="SocialEvents">{message}</a></div> */}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </Accordion.Collapse>
            </div>
        )
    }

    const [dk, setDk] = useState(0);

    var may6 = new Date("2022-05-06");
    var may13 = new Date("2022-05-13");
    var may20 = new Date("2022-05-20");
    var may27 = new Date("2022-05-27");

    var now = new Date("2022-05-14");

    var defaultKey = 0;

    if (now > may6) {
        defaultKey = 1;
    } else if (now > may13) {
        defaultKey = 2;
    }
    // } else if (now > may20) {
    //     defaultKey = 3;
    // } else if (now > may27) {
    //     defaultKey = 0;
    // }

    function ContextAwareToggle({ item, children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const title = item.title
        const date = item.date
        const subinfo = item.subinfo

        const isCurrentEventKey = activeEventKey === eventKey;
        const active = { backgroundColor: '#dceeff', padding: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5, cursor: 'pointer' }
        const inActive = { backgroundColor: '#ffffff', padding: 20, borderRadius: 5, cursor: 'pointer' }

        const activeBar = { backgroundColor: '#dceeff', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }
        const inActiveBar = { backgroundColor: '#ffffff', display: 'flex', color: "#444444", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }

        return (
            <div
                onClick={decoratedOnClick}
                style={isCurrentEventKey ? activeBar : inActiveBar}
            >
                <div

                    style={isCurrentEventKey ? active : inActive}
                >
                    <p>{date}</p>
                    <h4>{title}</h4>
                    <p style={{ fontSize: 14, color: "#666666", marginBottom: 0 }}>{subinfo}</p>
                </div>
                <div style={{ padding: 20 }}>
                    {
                        isCurrentEventKey ? (<img src={ArrowUp} width="20" />) : (<img src={ArrowDown} width="20" />)
                    }
                </div>
            </div>

        );
    }

    function ContextAwareToggle2({ info, children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const { date, duration, title, subinfo, sessions } = info;

        const isCurrentEventKey = activeEventKey === eventKey;
        console.log(isCurrentEventKey, eventKey);

        return (
            <div onClick={decoratedOnClick} className="ScheduleAccordianHeader">
                <div>
                    <div className="ScheduleAccordianDate">{date}, {duration}</div>
                    <div className="ScheduleAccordionTitle">{title}</div>
                    {/* <p style={{ fontSize: 14, color: "#666666", marginBottom: 0 }}>{subinfo}</p> */}
                </div>
                <div>
                    {
                        isCurrentEventKey ? (<img src={ArrowUp} width="20" />) : (<img src={ArrowDown} width="20" />)
                    }
                </div>
            </div>

        );
    }

    const { date, duration, title, subinfo, sessions } = Friday14June2024;

    return (
        <>
            <div style={{ backgroundColor: "#fafafa" }}>
                <Container>
                    <main style={{ paddingTop: 30, alignContent: 'center', margin: 'auto' }}>
                        <div style={{ textAlign: 'left' }}>
                            <h1 style={{ marginTop: 20, marginBottom: 20 }}>Schedule</h1>
                            <div style={{ borderBottom: '1px solid #121d2a' }}><p>The following schedule is planned. Please note that a free lunch will be served at Brunel University London for those joining us on campus.</p></div>


                            <Container>
                            <div className="ScheduleAccordianData">
                        {sessions.map((item, index) => {

                            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;

                            if (format === 'session') {
                                return (<div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                    <div className="HomeModalSessionTitle">{title}</div>
                                    <div className="HomeModalSessionChair">{chair}</div>
                                    {item.events.map((event, index) => {
                                        return (
                                            <div className="HomeModalSession">
                                                <div className="HomeModalSessionTime">{event.time}</div>
                                                {
                                                    event.format === "keynote" ? <div className="HomeModalSessionKeyNote">{event.title}</div> : <div className="HomeModalSessionEvent">{event.title}</div>
                                                }
                                                {/* <div className="HomeModalSessionEvent">{event.title}</div> */}
                                                <div className="HomeModalSessionPresentation">{event.presentation}</div>
                                            </div>)
                                    })}
                                </div>)
                            }

                            if (format === 'lunch') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                    </div>
                                )
                            }

                            if (format === 'break') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                    </div>
                                )
                            }

                            if (format === 'social') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                        <LinkContainer to="/socialEvents">
                                            <div className="HomeModalSessionLink">{message}</div>
                                        </LinkContainer>
                                        {/* <div className="HomeModalSessionEvent"><a href="SocialEvents">{message}</a></div> */}
                                    </div>
                                )
                            }

                            if (format === 'poster') {
                                return (
                                    <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                                        <div className="HomeModalSessionTime">{time}</div>
                                        <div className="HomeModalSessionEvent">{title}</div>
                                        <div className="HomeModalSessionPresentation">{message}</div>
                                    </div>
                                )
                            }

                            return null;
                        })}
                    </div>



                            </Container><br />
                        </div>
                    </main>
                </Container>
            </div>
            {/* <RegistrationSection /> */}
        </>
    );
}