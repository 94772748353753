import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Home from "./routes/home";
import Registration from "./routes/registration";
import Schedule from "./routes/schedule"
import Speakers from "./routes/speakers"
import Committee from "./routes/committee"
import Contact from "./routes/contact"
import Location from "./routes/location"
import SocialEvents from "./routes/socialEvents"
import ScrollToTop from "./functions/scrollToTop"

import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={<Home />}
        />
        <Route path="/:register" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="registration" element={<Registration />} />
        <Route path="schedule">
          <Route index element={<Schedule />} />
          <Route path=":activeVal" element={<Schedule />} />
        </Route>
        <Route path="speakers" element={<Speakers />} />
        <Route path="committee" element={<Committee />} />
        <Route path="contact" element={<Contact />} />
        <Route path="location" element={<Location />} />
        <Route path="socialEvents" element={<SocialEvents />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);

{/* <Route path="schedule" element={<Schedule />} /> */}