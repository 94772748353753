import { InlineMath, BlockMath } from 'react-katex';

const eq1 = <InlineMath>{"\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g_{p}}{p} \\small = 0"}</InlineMath>

const speakers = [
  {
    name: "Femi J. Olorunniji",
    last: "Olorunniji",
    profileUrl: "https://www.ljmu.ac.uk/about-us/staff-profiles/faculty-of-science/pharmacy-and-biomolecular-sciences/femi-olorunniji",
    image: null,
    bio: null,
    institution: "School of Pharmacy & Biomolecular Sciences, Faculty of Science, Liverpool John Moores University, Byrom Street, Liverpool L3 3AF, U.K.",
    presentation: {
      title: "Regulation of site-specific recombination by large serine integrases",
      abstract: [
        {textPara: "Large Serine Integrases (LSIs) are enzymes that promote recombination reactions between short distinct sequences of DNA called attachment sites (attB and attP) and they carry out complete DNA cutting and rejoining without leaving any broken ends behind. Integrases have been widely used to promote the insertion of foreign DNA into the genomes of cells, plants and animals. LSI-catalysed reactions can be seamlessly reversed by the addition of a second protein called a recombination directionality factor (RDF). We are using genetic and computational approaches to identify novel RDFs and to study how the LSIs interact with their cognate RDFs. Despite the diversity of the RDFs there is a common pattern in the way they bind to and regulate the function of the integrases. In addition, we are developing LSIs and their RDFs as orthogonally-acting genome editing tools that mediate predictable, controllable, and reversible rearrangements of DNA modules. "}
      ]
    }
  },
  {
    name: "Henning Hermjakob",
    last: "Hermjakob",
    profileUrl: "https://www.ebi.ac.uk/people/person/henning-hermjakob/",
    image: null,
    bio: null,
    institution: "Head of Molecular Systems, EBI",
    presentation: {
      title: "EMBL-EBI Network resources: From Interactions to Models",
      abstract: [
        {textPara: "The Molecular Networks team at the European Bioinformatics Institute provides resources that focus on the interactions and functional connectivity of biomolecules, spanning protein, oligonucleotide and small molecule components. These data reflect the coordinated self-organization and assembly of multi-component, regulated and emergent complex biological functions, building on reference resources such as ChEBI, UniProt, Ensembl, and the Complex Portal. The resources provide network data at increasing levels of abstraction, from primary molecular interaction data in IntAct via aggregated and curated pathway data in Reactome to mathematical models of biological systems in BioModels. "},
        {textPara: "Since its inception, the IntAct molecular interaction database (Del Toro et al. 2022) has emphasized a detailed, “deep” curation model, abstracting not only interaction partners, but also interaction details like experimental context, binding sites, PTMs, and mutations from the scientific literature. This detailed curation has allowed us to publish, among others, a key reference dataset containing 28,000 annotations fully describing the effect of individual point sequence changes on physical protein interactions, abstracted from the annotation of all partners in the International Molecular Exchange consortium (IMEx) partners over 14 years."},
        {textPara: "The Reactome database of curated human pathways (Milacic et al, 2024) currently covers 11,392 proteins in 15,046 reactions supported by 37,933 literature references (Release 87, December 2023). Pathway analysis capabilities range from simple gene enrichment to quantitative multi-omics analysis with ReactomeGSA (Griss et al. 2020). Analysis can be submitted through an R interface, and results are presented interactively in a visually attractive genome-wide results overview based on Voronoi maps. ReactomeGSA now includes a function that enables users to directly search for public datasets in Expression Atlas (Moreno et al., 2022) and GREIN (Mahi et al., 2019) from within ReactomeGSA, that can then be loaded into a ReactomeGSA analysis. These new features are available through a completely re-developed web interface as well as the ReactomeGSA Bioconductor R package."},
        {textPara: "The BioModels database provides an open resource of high quality, validated systems biology models in the community standard SBML representation, an essential service, as research shows that around 50% of published systems biology models are not directly reproducible from the associated publication (Tiwari et al. 2021). Building on these results, we will discuss recent approaches and challenges in reproducing machine learning based models. All resources are open source, open data, distributed under permissive licenses (CC0, CC-BY)."}
      ],
    }
  },
  {
    name: "Prof. Richard Goldstein",
    last: "Goldstein",
    profileUrl: "https://www.ucl.ac.uk/infection-immunity/people/professor-richard-goldstein",
    image: null,
    bio: null,
    institution: "UCL",
    presentation: {
      title: "TBD",
      abstract: [
        {textPara: "TBD"},
        {textPara: "TBD"}],
    }
  }
  // {
  //   name: "Dr Carl Barton",
  //   last: "Barton",
  //   profileUrl: null,
  //   image: null,
  //   bio: null,
  //   institution: "Birkbeck University",
  //   presentation: {
  //     title: "On the average-case complexity of pattern matching with wildcards",
  //     abstract: [
  //       {multiPara: [
  //         {type: 'text', content: "Pattern matching with wildcards is a string matching problem with the goal of finding all factors of a text"},
  //         {type: 'eq', content: "t"}, 
  //         {type: 'text', content: "of length"},
  //         {type: 'eq', content: "n"},
  //         {type: 'text', content: "that match a pattern"},
  //         {type: 'eq', content: "x"},
  //         {type: 'text', content: "of length"},
  //         {type: 'eq', content: "m \\mathrm{,}"},
  //         {type: 'text', content: "where wildcards (characters that match everything) may be present. In this paper we present a number of complexity results and fast average-case algorithms for pattern matching where wildcards are allowed in the pattern, however, the results are easily adapted to the case where wildcards are allowed in the text as well. We analyse the"},
  //         {type: 'text', content: "average-case complexity of these algorithms and derive non-trivial time bounds. These are the first results on the average- case complexity of pattern matching with wildcards which provide a provable separation in time complexity between exact pattern matching and pattern matching with wildcards. We introduce the wc-period of a string which is the period of the binary mask"},
  //         {type: 'eq', content: "x_b \\thickspace \\mathrm{where} \\thickspace x_{b} \\left [ i \\right ] = a\\thickspace \\mathit{iff} \\thickspace x \\left [i \\right] \\neq \\phi"},
  //         {type: 'text', content: "and"},
  //         {type: 'eq', content: "b"},
  //         {type: 'text', content: "otherwise. We denote the length of the wc-period of a string"},
  //         {type: 'eq', content: "x"},
  //         {type: 'text', content: "by"},
  //         {type: 'eq', content: "\\mathrm {WCP}(x)."},
  //         {type: 'text', content: "We show the following results for constant"},
  //         {type: 'eq', content: "0 < ε < 1"},
  //         {type: 'text', content: "and a pattern"},
  //         {type: 'eq', content: "x"},
  //         {type: 'text', content: "of length"},
  //         {type: 'eq', content: "m \\thickspace\\mathrm{and}\\thickspace g"},
  //         {type: 'text', content: "wildcards with"},
  //         {type: 'eq', content: "\\mathrm{WCP}(x) = p"},
  //         {type: 'text', content: "the prefix of length"},
  //         {type: 'eq', content: "p"},
  //         {type: 'text', content: "contains"},
  //         {type: 'eq', content: "g_{p}"},
  //         {type: 'text', content: "wildcards:"}
  //       ]},
  //       {multiPara: [
  //         {type: 'text', content: "if"},
  //         {type: 'eq', content: "\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g_{p}}{p} \\small = 0"},
  //         {type: 'text', content: "there is an optimal algorithm running in"},
  //         {type: 'eq', content: "{O} \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m}{m} \\scriptsize\\right )"},
  //         {type: 'text', content: "-time on average."}
  //       ]},
  //       {multiPara: [
  //         {type: 'text', content: "if"},
  //         {type: 'eq', content: "\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g_{p}}{p} \\small = 1 - {\\normalsize\\varepsilon}"},
  //         {type: 'text', content: "there is an optimal algorithm running in"},
  //         {type: 'eq', content: "{O} \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m\\thickspace log_{2}\\thickspace{p}}{m} \\right )"},
  //         {type: 'text', content: "-time on average."}
  //       ]},
  //       {multiPara: [
  //         {type: 'text', content: "if"},
  //         {type: 'eq', content: "\\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\normalsize\\frac{g}{m} = \\overset{\\lim}{\\scriptsize{m} {\\rightarrow} {\\infty}} \\thickspace \\small 1 - f\\left (m \\right )\\small = 1"},
  //         {type: 'text', content: "there is an optimal algorithm running in"},
  //         {type: 'eq', content: "{\\Omega} \\left ( \\frac{n\\thickspace log_{\\sigma}\\thickspace m}{f\\left (m\\right )} \\right )"},
  //         {type: 'text', content: "-time on average."}
  //       ]}      
  //     ],
  //   }
  // },
  // {
  //   name: "Dr Giulia Bernardini",
  //   last: "Bernardini",
  //   profileUrl: "https://sites.google.com/view/giulia-bernardini",
  //   image: null,
  //   bio: null,
  //   institution: "University of Trieste",
  //   presentation: {
  //     title: "Reconstructing Phylogenetic Networks via Cherry Picking and Machine Learning",
  //     abstract: [{textPara:"Combining a set of phylogenetic trees into a single phylogenetic network that explains all of them is a fundamental challenge in evolutionary studies. Existing methods are computationally expensive and can either handle only small numbers of phylogenetic trees or are limited to severely restricted classes of networks. In this work, we apply the recently-introduced theoretical framework of cherry picking to design a class of efficient heuristics that are guaranteed to produce a network containing each of the input trees, for datasets consisting of binary trees. Some of the heuristics in this framework are based on the design and training of a machine learning model that captures essential information on the structure of the input trees and guides the algorithms towards better solutions. We also propose simple and fast randomised heuristics that are very effective when run multiple times. Unlike the existing exact methods, our heuristics are applicable to datasets of practical size, and the experimental study we conducted on both simulated and real data shows that these solutions are qualitatively good, always within some small constant factor from the optimum. Moreover, our machine-learned heuristics are one of the first applications of machine learning to phylogenetics and show its promise."}],
  //   }
  // },
  // {
  //   name: "Mr Mark Blyth",
  //   last: "Blyth",
  //   profileUrl: null,
  //   image: null,
  //   bio: null,
  //   institution: "University of Bristol",
  //   presentation: {
  //     title: "Experimental bifurcation analysis of biological systems using control-based continuation",
  //     abstract: [
  //       {textPara: "Control-based continuation (CBC) is an experimental testing method for identifying dynamical features of physical systems. Equilibria and limit cycles can be located --- both stable and unstable --- and bifurcations can be tracked, on a physical system, without a model. We start by providing a general introduction to feedback control, numerical continuation, and CBC. Next, recent work is introduced, on applying CBC to studying the dynamics of excitable cells. The dynamics of excitable cells such as neurons are well-understood. Biological communities have developed a sophisticated arsenal of theoretical and experimental methods to study and explain observed behaviours,. Similarly, the mathematical community has complemented this with a bifurcation-theoretic framework, and an associated suite of numerical and analytic tools, to model and predict excitability properties. I will motivate CBC as a bridge between these two perspectives, and highlight how this can lead to novel experimental methods. Numerical studies on other systems will also be considered."}
  //     ],
  //   }
  // },
  // {
  //   name: "Dr Veronica Guerrini",
  //   last: "Guerrini",
  //   profileUrl: null,
  //   image: null,
  //   bio: null,
  //   institution: "University of Pisa",
  //   presentation: {
  //     title: "Lossy compression preserving variant calling based on the BWT of FASTQ data",
  //     abstract: [{textPara: "A standard format used for storing the output of high-throughput sequencing experiments is the FASTQ format. It comprises three main components: (i) headers, (ii) nucleotides sequences, and (iii) quality scores. FASTQ files are widely used in genotyping, where sequencing data are mapped into a reference genome to discover variants. Compressors specialised for FASTQ data exploit their redundancy mainly focusing on either the nucleotides or the quality scores component. We consider the novel problem of lossy compressing FASTQ data, in a reference-free way, by modifying both components (nucleotides and quality scores) at the same time, while preserving the information contained in the original FASTQ for variant discovery. The strategy we introduce is based on an extension of the Burrows-Wheeler Transform (EBWT) and on the positional clustering framework. We also explore the possibility of parallelizing the strategy for large FASTQ files, and analyse how reordering reads can improve the compression ratio as the number of threads increases. Experimental results show that our lossy compression scheme achieves good compression while preserving variant calling performance comparable to that with original data."}],
  //   }
  // },
  // {
  //   name: "Dr Mostafa Herajy",
  //   last: "Herajy",
  //   profileUrl: "",
  //   image: null,
  //   bio: null,
  //   institution: "Faculty of Science, Port Said University, Egypt",
  //   presentation: {
  //     title: "A graphical approach for hybrid simulation of 3D diffusion bio-models via coloured hybrid Petri nets",
  //     abstract: [
  //       {textPara: "Petri nets are visual languages that can provide a valuable tool for representing and simulating many dynamic systems including biological systems. Petri nets simply consists of places and transitions connected together via directed arcs. A special class of Petri nets are hybrid Petri nets which combine the powers of discrete and continuous modelling in a single model. Moreover, coloured Petri nets are an excellent choice for exploring large biological models, particularly when there are repetitions of components. Such models can be easily adapted by slight modifications of parameter values related to colours. Similarly, coloured hybrid Petri nets combines stochastic and deterministic events over a continuous time scale at the coloured level."},
  //       {textPara: "In this talk, the power of using hybrid Petri nets to model dynamical systems are presented. We illustrate the usage of coloured Petri nets to model complex systems using calcium diffusion.  We first construct a 2D (graphical) model of calcium diffusion which mimic calcium diffusion along with a stochastic gating system which control calcium flow. Afterwards, we extend the colored 2D HPN into 3D one. The 2D and 3D models consists of thousands of places and transitions."}
  //     ],
  //   }
  // },
  // {
  //   name: "Prof M. Sohel Rahman",
  //   last: "Rahman",
  //   profileUrl: null,
  //   image: null,
  //   bio: "http://msrahman.buet.ac.bd",
  //   institution: "Bangladesh University of Engineering and Technology (BUET)",
  //   presentation: {
  //     title: "Phylogeny-aware multi-objective optimization approaches",
  //     abstract: [
  //       {textPara: "In this talk, we systematically study the question of whether an application-aware (in this case phylogeny-aware) metric can guide us in choosing appropriate multi-objective (MO) formulations that can result in better phylogeny estimation. Employing MO metaheuristics, we demonstrate that (a) trees estimated on the alignments generated by MO formulation are substantially better than the trees estimated on the alignments generated by the state-of-the-art MSA tools and (b) highly accurate alignments with respect to popular measures do not necessarily lead to highly accurate phylogenetic trees."}
  //     ],
  //   }
  // },
  // {
  //   name: "Prof Reidun Twarock",
  //   last: "Twarock",
  //   profileUrl: "https://www-users.york.ac.uk/~rt507/",
  //   image: null,
  //   bio: null,
  //   institution: "University of York",
  //   presentation: {
  //     title: "Hamiltonian Paths Analysis: Viral geometry informed data mining of viral genomes",
  //     abstract: [
  //       {textPara:"Viruses package their genomes into protein containers, called capsids, that provide protection and transport between rounds of infection. Viral capsids are formed from multiple copies of identical proteins that are arranged in specific geometric arrangements that we classified in the framework of Viral Tiling theory. Using these insights into the principles of viral geometry as constraints on a bioinformatics analysis of viral sequences, we were able to discover sparse and cryptic repeat patterns that are important for virus assembly. In this talk, I will introduce the method, which is known as known as Hamiltonian Paths Analysis, because it relies on Hamiltonian paths associated with the tiling models of viral geometry. I will demonstrate the predictive power of the approach and discuss how it has enabled the discovery of a novel type of antiviral drug target."}
  //     ],
  //   }
  // }
]

export { speakers };