import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BioEvo from '../assets/bioEvo.png';
import BioDes from '../assets/bioDes.png';
import BioNet from '../assets/bioNet.png'
import Sequences from '../assets/sequences.svg'
//import Sequences2 from '../assets/sequences.svg'
import Graphs from '../assets/graphs.svg'
//import Graphs2 from '../assets/graphs.svg'
import StatisticalModelling from '../assets/statistical_modelling.svg'
//import StatisticalModelling2 from '../assets/statistical_modelling.svg'
import Disucssions from '../assets/discussions.svg'
//import Disucssions2 from '../assets/discussions.svg'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import bgImage from '../assets/backgroundImage.jpg'
// import bgImage from '../assets/backgroundColor.jpg'
// import bgImage from '../assets/background2.jpg'

import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom'

import ReactCardFlip from 'react-card-flip';

import Badge from 'react-bootstrap/Badge'

import RegistrationSection from '../components/registrationSection'
import FundingSection from '../components/fundingSection'

import { Friday14June2024, Monday12June, Tuesday13June } from '../data/schedule';

import '../bbw.css'
import '../styles/home.css';

export default function Home() {

  // const [dAK, setDAK] = useState(0);

  const { register } = useParams();

  // alert(JSON.stringify(useParams()))

  const [showDesign, setShowDesign] = useState(false);
  const handleCloseDesign = () => setShowDesign(false);
  const handleShowDesign = () => setShowDesign(true);

  const [showEvolution, setShowEvolution] = useState(false);
  const handleCloseEvolution = () => setShowEvolution(false);
  const handleShowEvolution = () => setShowEvolution(true);

  const [showNetworks, setShowNetworks] = useState(false);
  const handleCloseNetworks = () => setShowNetworks(false);
  const handleShowNetworks = () => setShowNetworks(true);

  const [showPosters, setShowPosters] = useState(false);
  const handleClosePosters = () => setShowPosters(false);
  const handleShowPosters = () => setShowPosters(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showGraph, setShowGraph] = useState(false);
  const handleCloseGraph = () => setShowGraph(false);
  const handleShowGraph = () => setShowGraph(true);

  const [showStatistical, setShowStatistical] = useState(false);
  const handleCloseStatistical = () => setShowStatistical(false);
  const handleShowStatistical = () => setShowStatistical(true);

  const [showDiscussions, setShowDiscussions] = useState(false);
  const handleCloseDiscussions = () => setShowDiscussions(false);
  const handleShowDiscussions = () => setShowDiscussions(true);

  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => setShowForm(false);
  const handleShowForm = () => setShowForm(true);

  useEffect(() => {
    console.log(`register: ${register}`);
    if (register == 't') {
      setShowForm(true)
    }
  }, [register])


  const session1 = Friday14June2024.sessions[0];



  return (
    <>
      <div style={{ backgroundColor: "#eeeeee", backgroundSize: 'cover', backgroundImage: `url(${bgImage})`, backgroundPositionX: 0, paddingBottom: 20 }}>
        <Container>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 12, offset: 0 }}>
              <p className="home__title-small">&nbsp;</p>
              <h1 className="home__title">Brunel Bioinformatics Symposium 2024</h1>
              <h1 className="home__subtitle">7th June, 2024</h1>
              <p className="home__sentance">The <a href="https://www.brunel.ac.uk/research/Groups/Computational-Biology" target="_blank" rel="noreferrer">Computational Biology group</a>, at <a href="https://www.brunel.ac.uk/" target="_blank" rel="noreferrer">Brunel University London</a> is very excited to invite all to the Brunel Bioinformatics Symposium 2024.</p>

              <p className="home__sentance">We are hoping that this exciting one-day event will showcase excellent Computational Biology research from across the UK and foster long lasting research collaborations.</p>
              <hr style={{margin: '3rem 0px'}} />
              <p className="home__sentance">This workshop will consist of three sessions running on the 7th of June, 2024. We aim to bring in experts who will discuss their work within Biomolecular design, evolution and networks.</p>
              {/* Each session will have a round table discussion, discussing future challenges from the day's session.</p> */}
              {/* <p className="home__sentance">These sessions are open to all. Please use the Register button to access and complete the registration form. You can attend these hybrid sessions either in person at Brunel University or online.</p> */}
            
            </Col></Row>

            <Row xs={1} sm={2} md={2} lg={2} xl={4} style={{ display: 'flex' }}>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 3, offset: 0 }}>

              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Biomolecular Design</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">Session 1, 11:00-12:25 BST</Card.Subtitle>
                <div styles={{ height: 60 }}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={Sequences} />
                </div>
                <Card.Body>
                  <Card.Text className="home__seminarcard__text">
                  Join us for an enlightening session as our guest speakers unveil their latest research in Biomolecular Design.
                  </Card.Text>
                </Card.Body>
                <Button size="sm" variant="dark" onClick={handleShowDesign}>Session schedule</Button>
              </Card>

            </Col>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 3, offset: 0 }}>
              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Biomolecular Evolution</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">Session 2, 13:30-14:45 BST</Card.Subtitle>
                <div styles={{ height: 60 }}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={Sequences} />
                </div>
                <Card.Body>

                  <Card.Text className="home__seminarcard__text">
                  Join us for an enlightening session as our guest speakers unveil their latest research in Biomolecular Evolution.
                  </Card.Text>
                </Card.Body>
                <Button size="sm" variant="dark" onClick={handleShowEvolution}>Session schedule</Button>
              </Card>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 3, offset: 0 }}>
              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Biomolecular Networks</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">Session 3, 15:00-16:35 BST</Card.Subtitle>
                <div styles={{ height: 60}}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={Sequences} />
                </div>
                <Card.Body>

                  <Card.Text className="home__seminarcard__text">
                  Join us for an enlightening session as our guest speakers unveil their latest research in Molecular Networks.
                  </Card.Text>
                </Card.Body>
                <Button size="sm" variant="dark" onClick={handleShowNetworks}>Session schedule</Button>
              </Card>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 3, offset: 0 }}>
              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Poster Session</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">16:35-18:00 BST</Card.Subtitle>
                {/* <div styles={{ height: 60 }}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={Sequences} />
                </div> */}
                <Card.Body>

                  <Card.Text className="home__seminarcard__text">
                  Join our symposium poster session on biomolecular design, evolution, and more! Explore cutting-edge research and innovation.
                  </Card.Text>
                </Card.Body>
                <Button size="sm" variant="dark" onClick={handleShowPosters}>Session schedule</Button>
              </Card>
            </Col>
            {/* <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 3, offset: 0 }}>
              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Statistical Modelling</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">Friday, 20th May, 13:00-16:00 BST</Card.Subtitle>
                <div styles={{ height: 60 }}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={StatisticalModelling} />
                </div>
                <Card.Body>

                  <Card.Text className="home__seminarcard__text">
                    Join us to hear our guest speakers present their latest research in the use of statistical modelling in Bioinformatics.
                  </Card.Text>
                </Card.Body>
                <Button size="sm" variant="dark" onClick={handleShowStatistical}>Session schedule</Button>

              </Card>
            </Col> */}
            {/* <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 3, offset: 0 }}>
              <Card className="home__seminarcard">
                <Card.Title className="home__seminarcard__title">Round Table Discussions</Card.Title>
                <Card.Subtitle className="home__seminarcard__subtitle">Friday, 27th May, 13:00-15:30 BST</Card.Subtitle>
                <div styles={{ height: 60 }}>
                  <Card.Img className="home__seminarcard__img" variant="top" src={Disucssions} />
                </div>
                <Card.Body style={{ borderRadius: 15 }}>

                  <Card.Text style={{ fontSize: 14 }}>
                    Join us for a round table discussion around the use of sequences, graphs and statistical modelling in Bioinformatics.
                  </Card.Text>

                </Card.Body>

                <Button size="sm" variant="dark" onClick={handleShowDiscussions}>Session schedule</Button>

              </Card>
            </Col> */}
          </Row>


            <Row>

            <Col>
            {/* <p className="home__sentance">These sessions are open to all. Please use the Register button to access and complete the registration form. You can attend these hybrid sessions either in person at Brunel University or online.</p> */}
           
              <p className="home__sentance">There are <strong>no registration costs</strong> for the event, and to <strong>register please complete the following <span onClick={() => setShowForm(true)}><u>form</u></span>.</strong></p>
              <hr style={{margin: '3rem 0px'}} />
              <p className="home__sentance"><strong>I would like to give a talk/present a poster:</strong><br />
We would like to invite young researchers (Doctoral and Postdoctoral researchers) to come and share their research across three research themes Biomolecular Design, Biomolecular Evolution, and Biomolecular Networks (more details can be found <a href="Schedule">here</a>).</p>
              <p className="home__sentance">To present your work either as a talk or poster or both, while registering, please submit an abstract of your work.</p>
              <p className="home__sentance">If you are <strong>selected for a talk</strong>, we will inform you on <strong>7th of May</strong>.</p>
              <p className="home__sentance"><strong>Travel awards:</strong><br />
Doctoral and postdoctoral researchers who are travelling to Brunel from outside of London and have been selected to give a talk are eligible for a travel bursary of up to £100. </p>
              <p className="home__sentance">For any further questions regarding the event, please email Dr. Sarath Dantu (email: <a href="mailto:sarath.dantu@brunel.ac.uk">sarath.dantu@brunel.ac.uk</a>)</p>

              <hr style={{margin: '3rem 0px'}} />
              <p className="home__sentance">This workshop will consist of three sessions running on the 7th of June, 2024. We aim to bring in experts who will discuss their work within Biomolecular design, evolution and networks.</p>
              {/* Each session will have a round table discussion, discussing future challenges from the day's session.</p> */}
              <p className="home__sentance">These sessions are open to all. Please use the Register button to access and complete the registration form. You can attend these hybrid sessions either in person at Brunel University or online.</p>
            </Col>
          </Row>

          <hr style={{margin: '3rem 0px'}}/>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }}>
              <p className="home__sentance">This workshop is being funded by Brunel University's Seminar Series Research Award scheme</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={showDesign} size="md" onHide={handleCloseDesign} centered>
        <div style={{ padding: 20 }}>
          <div className="HomeModalHeader">
            <div>
            <div className="HomeModalDuration">Friday 7th June, 11:00-12:25 BST</div>
            <div className="HomeModalTitle">Biomolecular Design</div>
            </div>
            <div>
              <div className="HomeModalClose" onClick={handleCloseDesign}>Close</div>
            </div>
          </div>

          {Friday14June2024.sessions.map((item, index) => {
            console.log(item);
            console.log(item.format);

            

            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;
            console.log(format);
            if(title.includes('Session 1')) {

            if (format === 'session') {
              return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
                {/* <div className="HomeModalSessionTitle">{title}</div> */}
                {/* <div className="HomeModalSessionChair">{chair}</div> */}
                {item.events.map((event, index) => {
                  return (
                  <div className="HomeModalSession">
                    <div className="HomeModalSessionTime">{event.time}</div>
                    {
                      event.format === "keynote" ? <div className="HomeModalSessionKeyNote">{event.title}</div> : <div className="HomeModalSessionEvent">{event.title}</div>
                    }
                    {/* <div className="HomeModalSessionEvent">{event.title}</div> */}
                    <div className="HomeModalSessionPresentation">{event.presentation}</div>
                  </div>)
                })}
              </div>)
            }
          }

          if (format === 'lunch') {

            return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
              <div className="HomeModalSessionTime">{item.time}</div>
              <div className="HomeModalSessionEvent">{item.title}</div>
            </div>)
          }
            

            if (format === 'social') {
              return (
                  <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                      <div className="HomeModalSessionTime">{time}</div>
                      <div className="HomeModalSessionEvent">{title}</div>
                      <LinkContainer to="/socialEvents">
                          <div className="HomeModalSessionLink">{message}</div>
                      </LinkContainer>
                      {/* <div className="HomeModalSessionEvent"><a href="SocialEvents">{message}</a></div> */}
                  </div>
              )
          }
          })}
        </div>
      </Modal>

      <Modal show={showEvolution} size="md" onHide={handleCloseEvolution} centered>
        <div style={{ padding: 20 }}>
          <div className="HomeModalHeader">
            <div>
            <div className="HomeModalDuration">Friday 7th June, 13:30-14:45 BST</div>
            <div className="HomeModalTitle">Biomolecular Evolution</div>
            </div>
            <div>
              <div className="HomeModalClose" onClick={handleCloseEvolution}>Close</div>
            </div>
          </div>

          {Friday14June2024.sessions.map((item, index) => {
            console.log(item);
            console.log(item.format);

            

            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;
            console.log(format);
            if(title.includes('Session 2')) {

            if (format === 'session') {
              return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
                {/* <div className="HomeModalSessionTitle">{title}</div> */}
                {/* <div className="HomeModalSessionChair">{chair}</div> */}
                {item.events.map((event, index) => {
                  return (
                  <div className="HomeModalSession">
                    <div className="HomeModalSessionTime">{event.time}</div>
                    {
                      event.format === "keynote" ? <div className="HomeModalSessionKeyNote">{event.title}</div>: <div className="HomeModalSessionEvent">{event.title}</div>
                    }
                    {/* <div className="HomeModalSessionEvent">{event.title}</div> */}
                    <div className="HomeModalSessionPresentation">{event.presentation}</div>
                  </div>)
                })}
              </div>)
            }
          }
            
          if (format === 'break') {

            return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
              <div className="HomeModalSessionTime">{item.time}</div>
              <div className="HomeModalSessionEvent">{item.title}</div>
            </div>)
          }

            if (format === 'social') {
              return (
                  <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                      <div className="HomeModalSessionTime">{time}</div>
                      <div className="HomeModalSessionEvent">{title}</div>
                      <LinkContainer to="/socialEvents">
                          <div className="HomeModalSessionLink">{message}</div>
                      </LinkContainer>
                      {/* <div className="HomeModalSessionEvent"><a href="SocialEvents">{message}</a></div> */}
                  </div>
              )
          }
          })}
        </div>
      </Modal>

      <Modal show={showNetworks} size="md" onHide={handleCloseNetworks} centered>
        <div style={{ padding: 20 }}>
          <div className="HomeModalHeader">
            <div>
            <div className="HomeModalDuration">Friday 7th June, 15:00-16:35 BST</div>
            <div className="HomeModalTitle">Biomolecular Networks</div>
            </div>
            <div>
              <div className="HomeModalClose" onClick={handleCloseNetworks}>Close</div>
            </div>
          </div>

          {Friday14June2024.sessions.map((item, index) => {
            console.log(item);
            console.log(item.format);

            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;
            console.log(format);
            if(title.includes('Session 3')) {

            if (format === 'session') {
              return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
                {/* <div className="HomeModalSessionTitle">{title}</div> */}
                {/* <div className="HomeModalSessionChair">{chair}</div> */}
                {item.events.map((event, index) => {
                  return (
                  <div className="HomeModalSession">
                    <div className="HomeModalSessionTime">{event.time}</div>
                    {
                      event.format === "keynote" ? <div className="HomeModalSessionKeyNote">{event.title}</div>: <div className="HomeModalSessionEvent">{event.title}</div>
                    }
                    {/* <div className="HomeModalSessionEvent">{event.title}</div> */}
                    <div className="HomeModalSessionPresentation">{event.presentation}</div>
                  </div>)
                })}
              </div>)
            }
            
          }
          })}
        </div>
      </Modal>

      <Modal show={showPosters} size="md" onHide={handleClosePosters} centered>
        <div style={{ padding: 20 }}>
          <div className="HomeModalHeader">
            <div>
            <div className="HomeModalDuration">Friday 7th June, 16:35-18:00 BST</div>
            <div className="HomeModalTitle">Poster Session</div>
            </div>
            <div>
              <div className="HomeModalClose" onClick={handleClosePosters}>Close</div>
            </div>
          </div>

          {Friday14June2024.sessions.map((item, index) => {
            console.log(item);
            console.log(item.format);

            

            let { format = null, title = null, time = null, events = null, message = null, chair = null} = item;
            console.log(format);
            if(title.includes('Poster')) {

            // if (format === 'session') {
            //   return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
            //     {/* <div className="HomeModalSessionTitle">{title}</div> */}
            //     {/* <div className="HomeModalSessionChair">{chair}</div> */}
            //     {item.events.map((event, index) => {
            //       return (
            //       <div className="HomeModalSession">
            //         <div className="HomeModalSessionTime">{event.time}</div>
            //         <div className="HomeModalSessionEvent">{event.title}</div>
            //         <div className="HomeModalSessionPresentation">{event.presentation}</div>
            //       </div>)
            //     })}
            //   </div>)
            // }

            if (format === 'poster') {
              return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
                {/* <div className="HomeModalSessionTitle">{title}</div> */}
                {/* <div className="HomeModalSessionChair">{chair}</div> */}
                {item.events.map((event, index) => {
                  return (
                  <div className="HomeModalSession">
                    <div className="HomeModalSessionTime">{event.time}</div>
                    <div className="HomeModalSessionEvent">{event.message}</div>
                    {/* <div className="HomeModalSessionPresentation">{event.presentation}</div> */}
                  </div>)
                })}
              </div>)
            }
            
            if (format === 'lunch') {

              return (<div style={{backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16}}>
                <div className="HomeModalSessionTime">{item.time}</div>
                <div className="HomeModalSessionEvent">{item.title}</div>
              </div>)
            }

          }
            

            if (format === 'social') {
              return (
                  <div style={{ backgroundColor: '#eeeeee', marginTop: 8, marginBottom: 8, padding: 16 }}>
                      <div className="HomeModalSessionTime">{time}</div>
                      <div className="HomeModalSessionEvent">{title}</div>
                      <LinkContainer to="/socialEvents">
                          <div className="HomeModalSessionLink">{message}</div>
                      </LinkContainer>
                      {/* <div className="HomeModalSessionEvent"><a href="SocialEvents">{message}</a></div> */}
                  </div>
              )
          }
          })}
        </div>
      </Modal>

      <Modal show={showForm} fullscreen={true} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, margin: 0 }}>
          <iframe width="100%" height="100%" src="https://forms.office.com/pages/responsepage.aspx?id=sZetTDVZA0GoZletmKFRfqrlRzNA3FhIqXT7a74cKNpUMVJFRU1VWk9FMEpPSlhBWFQ2WTVaUEw2SC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: 'none', maxWidth: '100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen />
        </Modal.Body>
      </Modal>
    </>
  );
}